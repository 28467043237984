import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

//* HOCs
import withUIContext from 'context/consumerHOC/UIConsumer';
import withLanguageContext from 'context/consumerHOC/LanguageConsumer';

//* Helpers
import { transformByYWorksVersion } from 'helpers/_gsapFunctions';
import { changeCommaToSlash } from 'helpers/_functions';

//* Components
import AnimationWrap from 'components/common/AnimationWrap';
import Image from 'components/common/Image';
import Video from 'components/common/Video';
import Text from 'components/common/Text';
import CustomLink from 'components/common/CustomLink';

//* Style
import FileSectionStyle from './style';

const fileObj = {
	landscape_left: {
		left: {
			main: 'landscape_small',
			file: 'landscape_small_file',
		},
		right: {
			main: 'square_small',
			file: 'square_small_file',
		},
	},
	landscape_right: {
		left: {
			main: 'square_small',
			file: 'square_small_file',
		},
		right: {
			main: 'landscape_small',
			file: 'landscape_small_file',
		},
	},
	square_equal: {
		left: {
			main: 'square_large',
			file: 'square_large_file',
		},
		right: {
			main: 'square_large',
			file: 'square_large_file',
		},
	},
	portrait_left: {
		left: {
			main: 'landscape_large',
			file: 'landscape_large_file',
		},
		right: {
			main: 'portrait',
			file: 'portrait_file',
		},
	},
	portrait_right: {
		left: {
			main: 'portrait',
			file: 'portrait_file',
		},
		right: {
			main: 'landscape_large',
			file: 'landscape_large_file',
		},
	},
};

const FilesSection = ({ typeAndClass, left_project, right_project, winWidth, related, viewAll, translate }) => {
	const { asPath } = useRouter();

	//! State
	const [secObj, setSecObj] = useState();
	const [leftFile, setLeftFile] = useState(null);
	const [rightFile, setRightFile] = useState(null);

	//! Refs
	const items = useRef([]);
	const leftWrapRef = useRef();
	const rightWrapRef = useRef();
	const animationInterval = useRef();
	const animationActiveIndex = useRef(0);

	useEffect(() => {
		setLeftFile(left_project[fileObj[typeAndClass].left.main]);
		setRightFile(right_project?.[fileObj[typeAndClass].right.main]);
	}, [left_project, right_project]);

	useEffect(() => {
		if (items?.current) {
			setSecObj([items.current]);
		}
	}, [items]);

	const handleMouseEnter = (e) => {
		clearInterval(animationInterval.current);
		animationActiveIndex.current = 0;
		handleImage(e, true);
	};

	const handleMouseLeave = (e) => {
		clearInterval(animationInterval.current);
		animationActiveIndex.current = 0;
		handleImage(e);
	};

	const handleImage = useCallback(
		(e, isHover = false) => {
			if (winWidth >= 1280) {
				const el = e.target;

				let files;
				let funcName;
				let main;

				if (checkLeftRight(el, 'leftWrap')) {
					main = left_project[fileObj[typeAndClass].left.main];
					files = [...left_project[fileObj[typeAndClass].left.file], main];
					funcName = 'setLeftFile';
				} else if (checkLeftRight(el, 'rightWrap') && right_project) {
					main = right_project[fileObj[typeAndClass].right.main];
					files = [...right_project[fileObj[typeAndClass].right.file], main];
					funcName = 'setRightFile';
				}

				if (isHover) {
					if (files[0].type === 'image') {
						eval(`${funcName}(files[animationActiveIndex.current])`);
						animationInterval.current = setInterval(() => {
							const index = animationActiveIndex.current + 1 < files.length ? animationActiveIndex.current + 1 : 0;
							animationActiveIndex.current = index;
							eval(`${funcName}(files[animationActiveIndex.current])`);
						}, 900);
					} else {
						eval(`${funcName}(files[animationActiveIndex.current])`);
					}
				} else {
					eval(`${funcName}(main)`);
				}
			}
		},
		[winWidth, left_project, right_project, animationActiveIndex]
	);

	const checkLeftRight = useCallback((el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`), []);

	const startAnimeArr = useCallback((x) => {
		//! Checkers
		const arr = [leftWrapRef.current, rightWrapRef.current];
		const filterArr = arr.filter((el) => typeof el !== 'undefined');

		if (x) {
			//! item, stagger, delay, duration
			transformByYWorksVersion(filterArr, 0.1, 0.2, 0.5);
		}
	}, []);

	const leftWrap = useMemo(() => {
		return (
			<div
				className={`leftWrap additionalWrap opacityZero`}
				ref={leftWrapRef}>
				{leftFile && (
					<>
						<div className={`fileWrap`}>
							<CustomLink
								url={`/works/${left_project.slug}`}
								ariaLabel={left_project.slug}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}>
								{leftFile.type === 'image' ? (
									<Image
										priority
										src={leftFile.url}
										alt={leftFile.alt}
									/>
								) : (
									<Video
										src={leftFile.url}
										autoplay
										loop
									/>
								)}
							</CustomLink>
						</div>

						<div className={`nameServiceWrap`}>
							<div className={`nameWrap`}>
								<CustomLink
									url={`/works/${left_project.slug}`}
									ariaLabel={left_project.slug}
									className={`btnLineAnim displayBlock`}>
									<Text className={`h6 uppercase blackRussian gilroySemiBold`}>{left_project.name}</Text>
								</CustomLink>
							</div>
							<Text className={`pS uppercase blackRussian gilroyMedium`}>{left_project.service && changeCommaToSlash(left_project.service)}</Text>
						</div>
					</>
				)}
			</div>
		);
	}, [leftFile]);

	const rightWrap = useMemo(() => {
		return (
			<div
				className={`rightWrap additionalWrap opacityZero`}
				ref={rightWrapRef}>
				{rightFile && (
					<>
						<div className={`fileWrap`}>
							<CustomLink
								url={`/works/${right_project.slug}`}
								ariaLabel={right_project.slug}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}>
								{rightFile.type === 'image' ? (
									<Image
										priority
										src={rightFile.url}
										alt={rightFile.alt}
									/>
								) : (
									<Video
										src={rightFile.url}
										autoplay
										loop
									/>
								)}
							</CustomLink>
						</div>

						<div className={`nameServiceWrap`}>
							<div className={`nameWrap`}>
								<CustomLink
									url={`/works/${right_project.slug}`}
									ariaLabel={right_project.slug}
									className={`btnLineAnim displayBlock`}>
									<Text className={`h6 uppercase blackRussian gilroySemiBold`}>{right_project.name}</Text>
								</CustomLink>
							</div>
							<Text className={`pS uppercase blackRussian gilroyMedium`}>{right_project.service && changeCommaToSlash(right_project.service)}</Text>
						</div>
					</>
				)}
			</div>
		);
	}, [rightFile]);

	return (
		<AnimationWrap
			state={secObj}
			startAnimeArr={startAnimeArr}
			dependency={asPath}>
			<FileSectionStyle
				ref={items}
				className={`fileSectionWrap`}>
				{related ? (
					<Text
						className={`h1 uppercase blackRussian gilroyMedium moreWorks`}
						text={`moreWorks`}
					/>
				) : null}

				<div className={`filesSection ${typeAndClass}`}>
					{leftWrap}
					{rightWrap}
				</div>

				{viewAll ? (
					<div className={`btnWrap`}>
						<div className={`btnInternalWrap `}>
							<CustomLink
								url={`/works/`}
								className={`uppercase h2 gilroyMedium blackRussian btnLineAnim`}
								content={translate('allWorks')}
							/>
						</div>
					</div>
				) : null}
			</FileSectionStyle>
		</AnimationWrap>
	);
};

export default withLanguageContext(withUIContext(FilesSection, ['winWidth']), ['translate']);
