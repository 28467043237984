import { memo as Memo, useMemo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Style
import PageStyle from './style';

const Page = Memo(({ meta, winWidth, ...props }) => {
	//! State
	const [headerHeight, setHeaderHeight] = useState();

	//! Router
	const router = useRouter();

	//! URL
	const url = useMemo(() => process.env.host + (router.locale !== 'en' ? '/' + router.locale : '') + router.asPath, [router]);

	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	useEffect(() => {
		setHeaderHeight(document.querySelector('.header').clientHeight);
	}, [winWidth]);

	//! Title
	const title = useMemo(() => meta?.title || 'Backbone Branding', [meta?.title]);

	//! Description
	const description = useMemo(() => meta?.description || 'meta description', [meta?.description]);

	return (
		<>
			<NextSeo
				title={title}
				description={description}
				canonical={url}
				type='website'
				additionalMetaTags={[
					{
						name: 'keywords',
						content: meta?.keywords,
					},
				]}
				openGraph={{
					url: url,
					title: title,
					description: description,
					images: [{ url: meta?.image?.url || process.env.host + '/logo/logoDark.svg', alt: meta?.image?.alt }],
				}}
				twitter={{
					handle: '@handle',
					site: '@site',
					cardType: 'summary_large_image',
				}}
			/>
			<PageStyle
				headerHeight={headerHeight}
				className={`page ${props.className}`}>
				{props.children}
			</PageStyle>
		</>
	);
});

export default withUIContext(Page, ['winWidth']);
