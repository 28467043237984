import styled from 'styled-components'

const FileSectionStyle = styled.section`
	//! Global sizes
	--nameAndServiceMT: var(--sp4x);

	//! landscape_left Sizes
	--landscape_small_left_width: 57.6%;
	--square_small_right_width: calc(100% - var(--landscape_small_left_width));
	--landscape_small_left_imageHeight: var(--imageGlobalHeight);
	--square_small_right_imageHeight: var(--imageGlobalHeight);

	//! landscape_right Sizes
	--square_small_left_width: 42.8%;
	--landscape_small_right_width: calc(100% - var(--square_small_left_width));
	--square_small_left_imageHeight: var(--imageGlobalHeight);
	--landscape_small_right_imageHeight: var(--imageGlobalHeight);

	//! square_equal Sizes
	--square_equal_width: 50%;
	--square_equal_imageHeight: 84.53%;

	//! portrait_left Sizes
	--landscape_large_left_width: 65.8%;
	--portrait_right_width: calc(100% - var(--landscape_large_left_width));
	--landscape_large_left_imageHeight: var(--imageGlobalHeight);
	--portrait_right_imageHeight: var(--imageGlobalHeight);

	//! portrait_right Sizes
	--portrait_left_width: 34.6%;
	--landscape_large_right_width: calc(100% - var(--portrait_left_width));
	--portrait_left_imageHeight: var(--imageGlobalHeight);
	--landscape_large_right_imageHeight: var(--imageGlobalHeight);

	//! Images Global Height size
	--imageGlobalHeight: 37.5vw;

	.filesSection {
		display: flex;
		flex-wrap: wrap;
		margin-left: calc(-1 * calc(var(--bannerDistance) / 2));
		margin-right: calc(-1 * calc(var(--bannerDistance) / 2));

		.leftWrap {
			transform: translateY(40%);
		}

		.rightWrap {
			transform: translateY(60%);
		}

		.additionalWrap {
			padding: 0 calc(var(--bannerDistance) / 2);

			.fileWrap {
				.imageCont,
				.videoCont {
					width: 100%;
				}
			}

			.nameServiceWrap {
				margin-top: var(--nameAndServiceMT);
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.nameWrap {
					padding-inline-end: var(--titleToTextDistance);
					margin-bottom: var(--sp2x);
				}
			}
		}

		//! landscape_left
		&.landscape_left {
			.leftWrap {
				width: var(--landscape_small_left_width);

				.imageCont,
				.videoCont {
					--proportion: var(--landscape_small_left_imageHeight);
				}
			}

			.rightWrap {
				width: var(--square_small_right_width);

				.imageCont,
				.videoCont {
					--proportion: var(--square_small_right_imageHeight);
				}
			}
		}

		//! landscape_right
		&.landscape_right {
			.leftWrap {
				width: var(--square_small_left_width);

				.imageCont,
				.videoCont {
					--proportion: var(--square_small_left_imageHeight);
				}
			}

			.rightWrap {
				width: var(--landscape_small_right_width);

				.imageCont,
				.videoCont {
					--proportion: var(--landscape_small_right_imageHeight);
				}
			}
		}

		//! square_equal
		&.square_equal {
			.leftWrap {
				width: var(--square_equal_width);

				.imageCont,
				.videoCont {
					--proportion: var(--square_equal_imageHeight);
				}
			}

			.rightWrap {
				width: var(--square_equal_width);

				.imageCont,
				.videoCont {
					--proportion: var(--square_equal_imageHeight);
				}
			}
		}

		//! portrait_left
		&.portrait_left {
			.leftWrap {
				width: var(--landscape_large_left_width);

				.imageCont,
				.videoCont {
					--proportion: var(--landscape_large_left_imageHeight);
				}
			}

			.rightWrap {
				width: var(--portrait_right_width);

				.imageCont,
				.videoCont {
					--proportion: var(--portrait_right_imageHeight);
				}
			}
		}

		//! portrait_right
		&.portrait_right {
			.leftWrap {
				width: var(--portrait_left_width);

				.imageCont,
				.videoCont {
					--proportion: var(--portrait_left_imageHeight);
				}
			}

			.rightWrap {
				width: var(--landscape_large_right_width);

				.imageCont,
				.videoCont {
					--proportion: var(--landscape_large_right_imageHeight);
				}
			}
		}
	}

	//! Line styles and animations
	.btnLineAnim {
		position: relative;

		&:before {
			display: block;
			content: '';
			position: absolute;
			top: 80%;
			height: 2px;
			width: 100%;
			right: 0;
			background-color: var(--blackRussian);
		}

		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 80%;
			left: 0;
			height: 2px;
			background-color: var(--blackRussian);
		}

		&:after,
		&:before {
			padding-bottom: 1px;
		}

		&:hover {
			&:before {
				width: 0%;
				animation: lineAnim var(--trTimeS) ease-out reverse;
			}

			&:after {
				animation: lineAnim var(--trTimeS) var(--trTimeN) ease-out forwards;
			}
		}

		@keyframes lineAnim {
			0% {
				width: 0%;
			}

			100% {
				width: 100%;
			}
		}
	}

	.btnWrap {
		display: flex;
		justify-content: center;
		margin-top: var(--sectionsDistance);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeL}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp4x);

		//! landscape_left Sizes
		--landscape_small_left_width: 57.1%;

		//! landscape_right Sizes
		--square_small_left_width: 43%;

		//! portrait_left Sizes
		--landscape_large_left_width: 65.2%;

		//! portrait_right Sizes
		--portrait_left_width: 34.8%;

		//! Images Global Height size
		--imageGlobalHeight: 37vw;

        //! square_equal Size
        --square_equal_imageHeight: 84.52%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp3x);

		//! landscape_left Sizes
		--landscape_small_left_width: 57.5%;

		//! landscape_right Sizes
		--square_small_left_width: 42.7%;

		//! portrait_left Sizes
		--landscape_large_left_width: 65.7%;

		//! portrait_right Sizes
		--portrait_left_width: 34.5%;

		//! Images Global Height size
		--imageGlobalHeight: 36.7vw;

        //! square_equal Size
        --square_equal_imageHeight: 84.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp3x);

		//! landscape_left Sizes
		--landscape_small_left_width: 57.7%;

		//! landscape_right Sizes
		--square_small_left_width: 42.9%;

		//! portrait_left Sizes
		--landscape_large_left_width: 65.9%;

		//! portrait_right Sizes
		--portrait_left_width: 34.7%;

		//! Images Global Height size
		--imageGlobalHeight: 37.1vw;

        //! square_equal Size
        --square_equal_imageHeight: 84.9%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp2x);

		//! landscape_left Sizes
		--landscape_small_left_width: 57.4%;

		//! landscape_right Sizes
		--square_small_left_width: 42.6%;

		//! portrait_left Sizes
		--landscape_large_left_width: 65.5%;

		//! portrait_right Sizes
		--portrait_left_width: 34.5%;

		//! Images Global Height size
		--imageGlobalHeight: 36.9vw;

        //! square_equal Size
        --square_equal_imageHeight: 85.51%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp2x);

		//! landscape_left Sizes
		--landscape_small_left_width: 57.4%;

		//! landscape_right Sizes
		--square_small_left_width: 42.6%;

		//! portrait_left Sizes
		--landscape_large_left_width: 65.5%;

		//! portrait_right Sizes
		--portrait_left_width: 34.5%;

		//! Images Global Height size
		--imageGlobalHeight: 37.5vw;

        //! square_equal Size
        --square_equal_imageHeight: 85.47%;

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			//! Disable Line styles and animations
			.btnLineAnim {
				&:hover {
					&:before {
						width: 100%;
						animation: unset;
					}

					&:after {
						animation: unset;
					}
				}

				&:after,
				&:before {
					padding-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSizeS}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp2x);

		//! landscape_left Sizes
		--landscape_small_left_width: 58.1%;

		//! landscape_right Sizes
		--square_small_left_width: 43.1%;

		//! portrait_left Sizes
		--landscape_large_left_width: 65.8%;

		//! portrait_right Sizes
		--portrait_left_width: 35.4%;

		//! Images Global Height size
		--imageGlobalHeight: 36.3vw;

        //! square_equal Size
        --square_equal_imageHeight: 85.29%;

		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.MobileSizeS}) {

        //! square_equal Size
        --square_equal_imageHeight: 85.16%;
    }

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		//! Global sizes
		--nameAndServiceMT: var(--sp1x);

		//! landscape_left Sizes
		--landscape_small_left_width: 100%;
		--square_small_right_width: 100%;
		--landscape_small_left_imageHeight: 100%;
		--square_small_right_imageHeight: 100%;

		//! landscape_right Sizes
		--square_small_left_width: 100%;
		--landscape_small_right_width: 100%;
		--square_small_left_imageHeight: 99.5%;
		--landscape_small_right_imageHeight: 71.1%;

		//! square_equal Sizes
		--square_equal_width: 100%;

		//! portrait_left Sizes
		--landscape_large_left_width: 100%;
		--portrait_right_width: 100%;
		--landscape_large_left_imageHeight: 62.4%;
		--portrait_right_imageHeight: 126.3%;

		//! portrait_right Sizes
		--portrait_left_width: 100%;
		--landscape_large_right_width: 100%;
		--portrait_left_imageHeight: 126.3%;
		--landscape_large_right_imageHeight: 62.4%;

        //! square_equal Size
        --square_equal_imageHeight: 85.16%;

		.rightWrap {
			margin-top: var(--sp8x);
		}

		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}
	}
`

export default FileSectionStyle
