import styled from 'styled-components'

const PageStyle = styled.main`
	padding-top: ${(props) => props.headerHeight}px;

	&.page-404 {
		padding-top: 0;
	}
`
export default PageStyle
