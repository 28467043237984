import { forwardRef, useEffect, useState, useMemo, useImperativeHandle, useRef } from 'react'
import Vimeo from '@u-wave/react-vimeo'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Style
import VideoStyle from './style'

const CustomVideo = forwardRef(({ clickable = false, muted = true, winWidth, ...props }, ref) => {
    //! Refs
    const videoContRef = useRef()
    const videoRef = useRef()

    useImperativeHandle(ref, () => videoRef.current, []);

    //! States
    const [isPlaying, setIsPlaying] = useState(props.autoplay)
    const [isPortrait, setIsPortrait] = useState(false)
    const [isReady, setIsReady] = useState(false)

    //! Checking Is Vimeo Url
    const isVimeo = useMemo(() => /^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(props.src), [props.src])

    //! Play / Pause
    useEffect(() => {
        if (isVimeo) {
            videoRef.current?.player.getPaused().then(function (paused) {

                if (paused && isPlaying) {
                    videoRef.current.player.play()
                }
                else if ((!paused || !props.autoplay) && !isPlaying) {
                    videoRef.current.player.pause()
                }
            });
        }
        else {
            if (isPlaying) {
                videoRef?.current?.play()
            }
            else {
                videoRef?.current?.pause()
            }
        }
    }, [isPlaying, isVimeo])

    useEffect(() => {
        if (isVimeo && videoContRef.current) {
            const itemObj = videoContRef.current.getBoundingClientRect()
            const size = itemObj.height / itemObj.width * 100

            setIsPortrait(size > 56.33)
        }
    }, [isVimeo, winWidth])

    return (
        <VideoStyle
            ref={videoContRef}
            onClick={() => { if (props.handleClick || clickable) { setIsPlaying(!isPlaying); props.handleClick() } }}
            className={`videoCont ${isReady ? 'ready' : ''} ${isPortrait && isVimeo ? 'vimeo-portrait' : ''} ${isVimeo ? 'vimeo-cont' : ''} ${props.className || ''}`}
        >
            {isVimeo ? (
                <Vimeo
                    ref={videoRef}
                    video={props.src}
                    autoplay={false}
                    muted={muted}
                    loop={true}
                    controls={false}
                    background={!!muted}
                    onReady={() => setIsReady(true)}
                    className="vimeo-video-cont"
                />
            ) : (
                <video
                    muted={typeof muted == 'boolean' ? muted : props.autoplay}
                    loop={props.loop}
                    controls={props.controls}
                    playsInline
                    ref={videoRef}
                    onClick={props.handleClick}
                >
                    <source src={props.src} type='video/webm' />
                    <source src={props.src} type='video/mp4' />
                </video>
            )}
        </VideoStyle>
    )
})

CustomVideo.displayName = 'CustomVideo'

export default withUIContext(CustomVideo, ['winWidth'])
