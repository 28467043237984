import styled from 'styled-components';

const VideoStyle = styled.div`
	--proportion: 56.33%;

	&.videoCont {
		position: relative;
		padding-top: var(--proportion);

		&:not(.ready) {
			background-color: var(--gray44);
			background: linear-gradient(-45deg, var(--chineseSilver), var(--lineColor));
			background-size: 300% 300%;
			animation: gradient 2s ease infinite;
		}

		@keyframes gradient {
			0% {
				background-position: 0% 50%;
			}

			50% {
				background-position: 100% 50%;
			}

			100% {
				background-position: 0% 50%;
			}
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}

	&.vimeo-cont {
		position: relative;
		overflow: hidden;

		.vimeo-video-cont {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			iframe {
				position: absolute;
				top: 50%;
				left: 50%;
				width: auto;
				min-width: 100%;
				height: 100%;
				transform: translate(-50%, -50%);
				pointer-events: none;
			}
		}

		&:not(.vimeo-portrait) .vimeo-video-cont {
			width: 100%;
			padding-top: 56.33%;
		}

		&.vimeo-portrait .vimeo-video-cont {
			width: calc(var(--proportion) / 56.33 * 100);
			padding-top: 0;
			height: 100%;
		}
	}
`;
export default VideoStyle;
